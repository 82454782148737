<template>
  <section>
    <kn-text-field
      class="mt-4"
      label="RFC o TaxID"
      v-model="model.tax_id"
      :rules="[rules.required]"
    />
    <kn-text-field
      label="Razón social"
      v-model="model.nombre_o_razon_social"
      :rules="[rules.required]"
    />
    <kn-auto-complete
      label="Uso factura"
      v-model="model.id_uso_factura"
      :rules="[rules.required]"
      :items="usosFactura"
      item-text="dato"
      item-value="id"
    />
  </section>
</template>

<script>
import { api } from '@/api/axios-base';
import { validationFormMixin } from '@/mixins/validationFormMixin'
import KnTextField from '../inputs/KnTextField.vue';
import KnAutoComplete from '../inputs/KnAutoComplete.vue';
export default {
  components: { KnTextField, KnAutoComplete },
  mixins: [validationFormMixin],
  props: {
    model: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      usosFactura: []
    }
  },
  async created() {
    this.usosFactura = await this.fetchUsosFactura()  
  },
  methods: {
    async fetchUsosFactura() {
      try {
        const response = await api.get('/personas/api/uso-factura-all/')
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al obtener usos factura');
      }
    }
  }
}
</script>

<style>

</style>