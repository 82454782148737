<template>
  <div>
    <v-row justify="space-between">
      <v-col 
        v-for="item in items"
        :key="`tab-${item.name}`"
      >
        <v-btn
          v-if="!tos"
          outlined
          tile
          block
          :to="tos ? {name: item.name} : null"
          :color="active(item.value)"
          @click="emitValue(item)"
        >
          {{item.name}}
        </v-btn>
        <router-link
          v-if="tos"
          :to="{name: item.name}"
          custom
          v-slot="{ isActive, route }"
        >
            <v-btn
              outlined
              tile
              block
              :to="{name: route.name}"
              :color="isActive ? 'primary' : 'grayoscuro'"
              @click="emitValue(item)"
            >
              {{ route.name }}
            </v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-progress-linear
          v-model="percentage"
          color="primary"
          rounded
          height="8"
        ></v-progress-linear>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    tos: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      valueDeterminate: 0,
      percentage: 0,
    }
  },
  created() {
    if (this.items.length) {
      this.percentage = this.items[0].percentage ? this.items[0].percentage : this.valueDeterminate = this.items[0].value
      this.valueDeterminate = this.items[0].value
    } else {
      this.valueDeterminate = 0
    }
  },
  methods: {
    active(val) {
      if (this.valueDeterminate === val) {
        return 'primary'
      }
      return 'grayoscuro'
    },
    emitValue(item) {
      this.percentage = item.percentage ? item.percentage : item.value
      this.valueDeterminate = item.value
      this.$emit('tabValue', this.valueDeterminate)
    }
  }
}
</script>

<style>

</style>