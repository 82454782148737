<template>
  <section>
    <slot name="searchPerson"></slot>
    <kn-text-field
      class="mt-4"
      label="Primer nombre"
      v-model="model.primer_nombre"
      :rules="[rules.required]"
    />
    <kn-text-field
      label="Segundo nombre"
      v-model="model.segundo_nombre"
      :rules="[]"
      :isMandatory="false"
    />
    <kn-text-field
      label="Apellido paterno"
      v-model="model.apellido_paterno"
      :rules="[rules.required]"
    />
    <kn-text-field
      label="Apellido materno"
      v-model="model.apellido_materno"
      :rules="[rules.required]"
    />
    <kn-select
      label="Seleccionar sexo"
      v-model="model.id_genero"
      :rules="[rules.required]"
      :items="genders"
      item-value="id"
      item-text="dato"
    />
    <kn-text-field
      label="Fecha de nacimiento"
      v-model="model.fecha_nacimiento"
      colsField="4"
      :rules="[rules.required]"
      type="date"
      hint="DD / MM / YY"
      persistent-hint
      @input="setAge(model.fecha_nacimiento)"
    />
    <kn-text-field
      label="Edad"
      v-model="model.edad"
      colsField="4"
      :rules="[]"
      type="number"
    />
    <kn-select
      v-if="isTutor"
      label="Parentesco"
      v-model="model.id_parentesco"
      :rules="[rules.required]"
      :items="relationship"
      item-value="id"
      item-text="dato"
    />
    <kn-check-box
      v-if="isTutor"
      label="Responsable económico:"
      v-model="model.responsable_economico"
      :rules="[]"
      :isMandatory="false"
    />
  </section>
</template>

<script>
import { apiMixin } from '@/mixins/apiMixin'
import { utilsMixin } from '../../mixins/utilsMixin'
import { validationFormMixin } from '@/mixins/validationFormMixin'
import KnTextField from '@/components/inputs/KnTextField.vue'
import KnSelect from '@/components/inputs/KnSelect.vue'
import KnCheckBox from '@/components/inputs/KnCheckBox.vue'
export default {
  components: {
    KnTextField,
    KnSelect,
    KnCheckBox
  },
  mixins: [
    apiMixin,
    utilsMixin,
    validationFormMixin
  ],
  props: {
    model: {
      type: Object,
      default: () => {}
    },
    isTutor: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      genders: [],
      relationship: [],
      personalInfo: {
        primer_nombre: null,
        segundo_nombre: null,
        apellido_paterno: null,
        apellido_materno: null,
        fecha_nacimiento: '2021-12-23',
        edad: 0,
        numero_identidad: null,
        email: null,
        telefono_casa: null,
        telefono_movil: null,
      }
    }
  },
  async created() {
    this.genders = await this.fetchResultsAll('personas', 'genero-all')
    this.relationship = await this.fetchResultsAll('personas', 'parentesco')
  },
  methods: {
    setAge(birthday) {
      this.model.edad = this.calculateAge(birthday)
    }
  }
}
</script>

<style>

</style>