import { api } from '../api/axios-base.js'
export const generalRepoMixin = {
  data() {
    return {
      errors: [],
      showAlert: false,
      alertType: 'success',
      alertText: 'Registro exitoso',
      alertColor: null,
    }
  },
  methods: {
    async postAddress(addresObj) {
      try {
        const response = await api.post('/personas/crear-direccion', addresObj)
        const responseData = await response.data
        // console.log('Respuesta direccion: ', responseData);
        if (responseData.e) {
          this.errors.push('No se pudo crear la dirección: ' + responseData.e.join(','))
          return
        }
        addresObj.id = responseData.id_objeto
      } catch (error) {
        this.errors.push('Error al intentar crear direccion')
        console.error('Error al intentar crear direccion.', error);
      }
    },
    async updateAddress(addresObj) {
      try {
        const response = await api.patch('/personas/update-direccion', addresObj)
        const responseData = await response.data
        // console.log('Respuesta actualizar direccion: ', responseData);
        if (responseData.e) {
          this.errors.push('No se pudo actualizar la dirección: ' + responseData.e.join(','))
        }
      } catch (error) {
        this.errors.push('Error al intentar actualizar direccion')
        console.error('Error al intentar actualizar direccion.', error);
      }
    },
    async postPersonalInfo(personalInfoObj) {
      try {
        const response = await api.post('/personas/crear-datos-personales', personalInfoObj)
        const responseData = await response.data
        // console.log('Respuesta datos personales: ', responseData);
        if (responseData.e) {
          this.errors.push('No se pudieron crear los datos personales: ' + responseData.e.join(','))
          return
        }
        personalInfoObj.id = responseData.id_objeto
      } catch (error) {
        this.errors.push('Error al intentar crear datos personales')
        console.error('Error al intentar crear datos personales.', error);
      }
    },
    async updatePersonalInfo(personalInfoObj) {
      try {
        const response = await api.patch('/personas/update-datos-personales', personalInfoObj)
        const responseData = await response.data
        // console.log('Respuesta actualizar datos personales: ', responseData);
        if (responseData.e) {
          this.errors.push('No se pudieron actualizar los datos personales: ' + responseData.e.join(','))
        }
      } catch (error) {
        this.errors.push('Error al intentar actualizar datos personales')
        console.error('Error al intentar actualizar personales.', error);
      }
    },
    async postTaxInfo(taxInfoObj) {
      try {
        const response = await api.post('/personas/crear-datos-fiscales', taxInfoObj)
        const responseData = await response.data
        // console.log('Respuesta datos fiscales: ', responseData);
        if (responseData.e) {
          this.errors.push('No se pudieron crear los datos fiscales: ' + responseData.e.join(','))
          return
        }
        taxInfoObj.id = responseData.id_objeto
      } catch (error) {
        this.errors.push('Error al intentar crear datos fiscales')
        console.error('Error al intentar crear datos fiscales.', error);
      }
    },
    async updateTaxInfo(taxInfoObj) {
      try {
        const response = await api.patch('/personas/update-datos-fiscales', taxInfoObj)
        const responseData = await response.data
        // console.log('Respuesta actualizar datos fiscales: ', responseData);
        if (responseData.e) {
          this.errors.push('No se pudieron crear los datos fiscales: ' + responseData.e.join(','))
        }
      } catch (error) {
        this.errors.push('Error al intentar actualizar datos fiscales')
        console.error('Error al intentar actualizar datos fiscales.', error);
      }
    },
    async postUser(userObj) {
      try {
        const response = await api.post('/land/crear-usuario', userObj)
        const responseData = await response.data
        // console.log('Respuesta usuario: ', responseData);
        if (responseData.e) {
          this.errors.push('No se pudo crear el usuario: ' + responseData.e.join(','))
          return
        }
        userObj.id = responseData.id_objeto
      } catch (error) {
        this.errors.push('Error al intentar crear usuario')
        console.error('Error al intentar crear usuario.', error);
      }
    },
  }
}