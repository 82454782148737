<template>
  <section>
    <kn-text-field
      label="Teléfono"
      v-model="model.telefono_movil"
      :rules="[rules.required]"
    />
    <kn-text-field
      label="Correo electrónico"
      v-model="model.email"
      :rules="[rules.required, rules.email]"
    />
  </section>
</template>

<script>
import { validationFormMixin } from '@/mixins/validationFormMixin'
import KnTextField from '../inputs/KnTextField.vue'
export default {
  components: { KnTextField },
  mixins: [validationFormMixin],
  props: {
    model: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style>

</style>